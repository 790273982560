import React from "react";
// import {
//   FaFacebookSquare,
//   FaTwitterSquare,
//   FaRedditSquare,
//   FaWhatsappSquare,
// } from "react-icons/fa";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";

const SocialShareButtons = ({ url, title,imageLink }) => {
  return (
    <div className="w-full flex justify-between">
      {/* <a
        target="_blank"
        rel="noreferrer"
        href={`https://www.facebook.com/dialog/share?app_id=1180206992856877&display=popup&href=${url}`}
      >
        <FaFacebookSquare className="text-[#3b5998] w-12 h-auto" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://twitter.com/intent/tweet?url=${url}`}
      >
        <FaTwitterSquare className="text-[#00acee] w-12 h-auto" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href={`http://www.reddit.com/submit?url=${url}&title=${title}`}
      >
        <FaRedditSquare className="text-[#ff4500] w-12 h-auto" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://api.whatsapp.com/send/?text=${url}`}
      >
        <FaWhatsappSquare className="text-[#25D366] w-12 h-auto" />
      </a> */}
              <div>
                <FacebookShareButton url={url} quote={title}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </div>
              <div>
                <TwitterShareButton url={url} title={title}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </div>
              <div>
                <LinkedinShareButton url={url} title={title}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </div>
              <div>
                <WhatsappShareButton url={url} separator=": " title={title} image={{imageLink}}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </div>
              <div>
                <EmailShareButton
                  url={url}
                  subject={title}
                  body="Check out this link!"
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </div>
    </div>
  );
};

export default SocialShareButtons;
